.App__container {
  text-align: center;
  opacity:1;
  transition: all 1s ease-in-out;
}

.App__container.loading {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: .1;
  animation: loading 1s ease-in-out infinite;
}

.Page {
  position:fixed;
  /*will-change: transform, left, right;*/
  padding:10px;
  top:0;
  left:0;
  right:0;
  bottom:0;
  background: red;
  font-weight: 900;
  color: white;
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all .3s ease-in-out;
}

.Page * {
  width:100%;
}

.Page.has-header {
  justify-content: start;
}

.Page.selected.zoomed {
  box-shadow: 0 0 60pt 20pt rgb(2, 146, 241);
  z-index: 3;
}

.Page.zoomed:hover {
  box-shadow: 0 0 60pt 20pt rgb(2, 146, 241);
  cursor: pointer;
}

.Page .title {
  font-size:92px;
}

.Page .title.big {
  font-size:92px;
}

.Page .content {
  font-size:24px;
  width: 80%;
  margin-top: 40px;
}

.Page .content li {
  text-align:left;
  font-size:48px;
}

.Page .content.big {
  font-size:32px;
}

.Page .header {
  margin-top:100px;
  font-size:64px;
  width:80%;
  text-align:left;
  border-bottom: 2px solid white;
}

.Page .footer {
  position: fixed;
  bottom:20px;
  font-size:16px;
}

.Page img.logo {
  top:unset;
  left:unset;
  height: auto;
  position: absolute;
  bottom: 10px;
  right: 10px;
  width:50px;
}

.Page.zoomed iframe {
  pointer-events: none;
}

.Page iframe,
.Page img {
  position: absolute;
  background: white;
  top: 50%;
  left: 50%;
  width: 80%;
  transform: translateX(-50%) translateY(-50%);
  height: 80%;
  border: 5px solid white;
  object-fit: cover;
}

.Page img.contain {
  object-fit: contain;
  border: 0;
}

.Page.hiddenright {
  right:-100%;
  left: 100%;
}

.Page.hiddenleft {
  left:-100%;
  right: 100%;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes loading {
  0% {
    opacity:.1;
  } 50% {
    opacity:.2;
  } 100% {
    opacity: .1;
  }
}
